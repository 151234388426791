
import { CargoController } from "@/app/ui/controllers/CargoController";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ReadyToCargoController } from "@/app/ui/controllers/ReadyToCargoController";
import {
  ReadyToCargo,
  ReadyToCargoListEntities,
  RequestReadyToCargoList
} from "@/domain/entities/ReadyToCargo";
import { Options, mixins } from "vue-class-component";
import PaginationV3 from "@/app/ui/components/pagination-v3/index.vue";
import CardReadyToCargo from "@/app/ui/views/cargo/booking-cargo/components/card-ready-to-cargo.vue";
import { GTMBookingCargoEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/booking-cargo-event";
import { ModalMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import { NGenController } from "@/app/ui/controllers/NGenController";
import { getTokenNGen } from "@/app/infrastructures/misc/Cookies";
import ModalPlaneAuto from "@/app/ui/views/cargo/booking-cargo/components/modal-plane-auto.vue";
import ModalCargoType from "@/app/ui/views/cargo/booking-cargo/components/modal-cargo-type.vue";
import {
  checkRolePermission,
  firstCapitalize,
  storeDatatoLocalStorage,
  commaDecimalToDecimal, dateTimePayload,
} from "@/app/infrastructures/misc/Utils";
import { CargoInputData, CargoDetailStt, DimensionData } from "@/domain/entities/Cargo";
import { cargoTypeData } from "../../modules/cargo-module";
import { CargoPlaneAutoData } from "@/domain/entities/Cargo";
import { AddBagOrStt } from "@/data/payload/api/CargoApiRequest";
import { gtmCargoData } from "../../modules/gtm";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { capitalize } from "@vue/shared";
import { flags } from "@/feature-flags";
import Print from "@/app/ui/views/cargo/modules/print.vue";
import OutGoingMixins from "@/app/ui/views/out-going-shipment/out-going-mixin";
import { defineAsyncComponent } from "@vue/runtime-core";
import { FlagsGamificationRTC } from "@/feature-flags/flag-gamification-rtc";
import {ClientController} from "@/app/ui/controllers/ClientController";
const Gamification = defineAsyncComponent(() =>
  import("@/app/ui/views/cargo/booking-cargo/components/gamification-rtc-modal/index.vue")
);
/* eslint-disable @typescript-eslint/camelcase */

@Options({
  components: {
    PaginationV3,
    CardReadyToCargo,
    ModalPlaneAuto,
    ModalCargoType,
    Print,
    Gamification
  }
})
export default class ReadyToCargoFlow extends mixins(OutGoingMixins) {
  beforeMount() {
    this.setFlowBooking("");
  }

  updated() {
    this.showSelectAirport = false;
  }

  async mounted() {
    this.getTabs();
    this.onReset();
    this.showSelectAirport = false;
    this.setDefaultDataToAirportPopup();
    this.formBooking.isChangeAirport = false;
    this.showSelectAirportToLocalStorage = false;
    ReadyToCargoController.setEventName("");
    CargoController.resetErrorDiffPiecesActualThanDimensionPieces();
    CargoController.setIsChangeVolumeActual(false);
    await this.getListRTCByCargoType();
    if (!this.readyToCargoList.data.length) {
      if (this.flagsGamification) {
        CargoController.setIsModalGamification(true);
        CargoController.setIsModalGamificationDone(false);
      }
      ReadyToCargoController.setFlowBooking("manual");
      CargoController.setProgressValue(0);
      ReadyToCargoController.setEventName("booking_cargo");
    }
  }

  get errorContainer() {
    const { clientValue, destinationCityRTC } = this;
    let errorDetails = {
      errorIcon: "data-not-found",
      errorTitle: "Data tidak ditemukan",
      errorMessage: "Maaf, data yang Anda cari tidak ditemukan."
    };

    if (clientValue && !destinationCityRTC) {
      errorDetails = {
        errorIcon: "noData_filter",
        errorTitle: "Belum ada daftar siap dikargo yang sesuai filter klien ini.",
        errorMessage: "Cek kembali atau ubah filter Anda."
      };
    }

    return errorDetails;
  }

  get clientValue() {
    return CargoController.clientValue;
  }

   get readyToCargoList () {
    return CargoController.readyToCargoList;
  }
  
  get destinationCityRTC () {
    return CargoController.destinationCityRTC
  }

  get flowBooking() {
    return ReadyToCargoController.flowBooking;
  }

  closePopUpPlane() {
    this.fetchAirportOrigin(this.originCity.code || "", true);
    this.onReset();
  }

  onReset() {
    this.setFlowBooking("");
    storeDatatoLocalStorage("cargo", []);
    storeDatatoLocalStorage("cargo-bag", []);
    storeDatatoLocalStorage("event-name", "");
    storeDatatoLocalStorage("rtc-flights-data", []);
    storeDatatoLocalStorage("calculate-flight-date", []);
    storeDatatoLocalStorage("show-select-airport", false);
    storeDatatoLocalStorage("cargo-type", {});
    storeDatatoLocalStorage("fill-form", false);
    storeDatatoLocalStorage("error-awb", "");
    storeDatatoLocalStorage("isEditReadyToCargo", false);
    storeDatatoLocalStorage("error-awb", "");
    storeDatatoLocalStorage("setShowPlaneSchedule", false);
    CargoController.setSTTResultFinal([]);
    CargoController.setBagResult([]);
    CargoController.setSourceFromLocalStorage([]);
    ReadyToCargoController.setCalculateFlightDate([]);
    ReadyToCargoController.setDetailFlightData([]);
    CargoController.setNotificationWeight(false);
    CargoController.setResetDestinationCity();
    CargoController.resetTotalActualGrossWeight();
    CargoController.resetCargoInputForm(new CargoInputData());
    CargoController.setShowModalCargoType(false);
    NGenController.setErrorAwb("");
    CargoController.setShowSelectAirportToLocalStorage(false);
    CargoController.resetIsEditReadyToCargo();
    ReadyToCargoController.setReadyToCargoDetail(new ReadyToCargo());
    CargoController.resetForm();
    CargoController.setIsChangeCargoType(false);
    this.readyToCargoList.pagination.page = 1;
  }

  setAirportPopUpModel(val: any) {
    CargoController.setAirportsPopupModel(val);
  }

  setDefaultDataToAirportPopup() {
    this.setAirportPopUpModel(
      JSON.parse(JSON.stringify(this.formBooking.airports))
    );
  }

  set showSelectAirportToLocalStorage(bool: boolean) {
    CargoController.setShowSelectAirportToLocalStorage(bool);
  }

  get showModalCargoPlaneAuto() {
    return CargoController.showSelectAirport;
  }

  set showSelectAirport(bool: boolean) {
    CargoController.setShowSelectAirport(bool);
  }

  get showModalCargoType() {
    return CargoController.showModalCargoType;
  }

  get isEditReadyToCargo() {
    return CargoController.isEditReadyToCargo;
  }

  get originCity(): OptionsClass {
    const detailDistrict = LocationController.districtDetail;
    return new OptionsClass({
      name: `${detailDistrict.cityCode} - ${firstCapitalize(
        detailDistrict.cityName.toLowerCase()
      )}`,
      code: detailDistrict.cityCode
    });
  }

  get formBooking() {
    return CargoController.form;
  }

  // transport type
  transportType = new OptionsClass();
  transportTypeData = [
    new OptionsClass({
      name: "plane",
      value: "plane"
    }),
    new OptionsClass({
      name: "truck",
      value: "truck"
    }),
    new OptionsClass({
      name: "train",
      value: "train"
    }),
    new OptionsClass({
      name: "ship",
      value: "ship"
    })
  ];

  async getListRTCByCargoType() {
    const tab = !this.tabs.length ? this.transportTypeData : this.tabs;
    for (const item of tab) {
      CargoController.setActiveTabs(item.name);
      await this.fetchReadyToCargoList();
      if (this.readyToCargoList.data.length) {
        CargoController.setActiveTabs(item.name);
        break;
      }
    }
  }

  get isLoading() {
    return MainAppController.loadingModal;
  }

  async fetchReadyToCargoList() {
    MainAppController.showLoading();
    CargoController.setReadyToCargoList (await ReadyToCargoController.getReadyToCargoList(
      new RequestReadyToCargoList({
        page: this.readyToCargoList.pagination.page,
        limit: this.readyToCargoList.pagination.limit,
        destinationCode: this.destinationCityRTC.code,
        transportType: this.activeTabs
      }))
    )
    MainAppController.closeLoading();
  }
  async initPlaneAuto(data: any): Promise<void> {
    await this.fetchAirportOrigin(this.originCity.code || "");
    await this.fetchAirportDestination(data.destinationCode);
  }

  fetchAirportOrigin(search: string, hideDefault = true) {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    return CargoController.getAirportListOrigin({
      airportCode: "",
      cityCode: search,
      page: 1,
      limit: 10,
      isLocation: false,
      hideDefault: hideDefault
    });
  }
  fetchAirportDestination(search: string) {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    return CargoController.getAirportListDestination({
      airportCode: "",
      cityCode: search,
      page: 1,
      limit: 10
    });
  }

  get cargoInputForm() {
    return CargoController.cargoInputForm;
  }

  showModalForm = false;
  dataReadyToCargo: ReadyToCargo = new ReadyToCargo();
  async setShowModalForm(data: ReadyToCargo, withPrint: boolean, label: boolean) {
    if (data.cargoType !== "plane") {
      this.withPrint = withPrint;
      this.withPrintLabel = label;
    }
    this.dataReadyToCargo = data;
    if(data.isMoreThanCutOffTime) {
      this.showModalForm = false;
      this.onProcessReadyToCargo(data);
      return;
    }
    await ReadyToCargoController.getDetailReadyToCargo(data.id, "rtc-process");
    await CargoController.mappingReadyToCargo();
    CargoController.mappingCommodityOptions();
    this.showModalForm = true;
  }

  showModalConfirmationBookCargo() {
    this.showModalForm = false;
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        image: "are-you-sure",
        title: "Konfirmasi Pembuatan",
        message: "Pastikan kembali, seluruh detail STT sudah benar dan sesuai.",
        textSuccess: "Process",
        textCancel: "Kembali",
        onSubmit: async () => {
            MainAppController.closeMessageModal();
            await this.onProcessReadyToCargo(this.dataReadyToCargo)
        },
        onClose: () => {
          MainAppController.closeMessageModal();
          this.showModalForm = true;
        }

      })
    )
  }

  async onProcessReadyToCargo(data: ReadyToCargo) {
    let isAuth = false;
    CargoController.setShowSelectAirportToLocalStorage(true);
    if (
      !getTokenNGen() &&
      !data.haveConfigCargoFlight &&
      data.cargoType === "plane"
    ) {
      isAuth = await NGenController.onLoginNGen();
    } else {
      isAuth = true;
    }
    if (isAuth) {
      if (this.cargoInputForm.id == 0) {
        await ReadyToCargoController.getDetailReadyToCargo(
          data.id,
          "rtc-process"
        );
      }
      this.formBooking.actualDimension = new DimensionData();
      CargoController.mappingReadyToCargo();
      this.setFlowBooking("rtc-process");
      ReadyToCargoController.setEventName("booking_cargo_rtc_result");

      if (this.formBooking.cargoTypeStatus === "auto") {
        this.formBooking.airportOrigin.airportCode =
          ReadyToCargoController.readyToCargoDetail.originCode;
        this.formBooking.airportDestination.airportCode =
          ReadyToCargoController.readyToCargoDetail.destinationCode;
      }
      if (data.isMoreThanCutOffTime) {
        await this.initPlaneAuto(data);
        CargoController.setAirportsPopupModel(
          JSON.parse(JSON.stringify(CargoController.form.airports))
        );
        this.showSelectAirport = true;
        this.formBooking.cargoType = "plane";
        this.formBooking.cargoTypeStatus = "auto";
        this.formBooking.isRtc = true;
        this.formBooking.actualTotalCargoGrossWeight = data.totalWeight.toString();
      } else {
        this.onSave();
      }
    }
  }

  get commodityOptions(): OptionsClass[] {
    return CargoController.commodityOptions
  }

  get commodityItem() {
    return CargoController.commodityItem;
  }

  onSelectCommodity(val: any) {
    if (val.value === this.commodityItem.value) {
      CargoController.setCommodityItem({
        value: "",
        name: ""
      })
      this.formBooking.cargoCommodityName = "";
      this.formBooking.cargoCommodityCode = "";
      this.formBooking.shcCode = "";
      this.formBooking.shcName = "";
      return;
    }
    CargoController.setCommodityItem(val)
    this.formBooking.cargoCommodityName = val.name;
    this.formBooking.cargoCommodityCode = val.value;
    this.formBooking.shcName = val.shcName;
    this.formBooking.shcCode = val.shcCode;
    const comodity = new OptionsClass({
      name: val.name,
      value: val.value,
      shcCode: val.shcCode,
      shcName: val.shcName
    });
    this.setCargoComodity(comodity);
  }

  setCargoComodity(value: OptionsClass) {
    CargoController.setCargoComodity(value);
  }

  get disableCommodityGroup(): boolean {
    return CargoController.disableCommodityGroup;
  }

  get readyToCargoDetail() {
    return ReadyToCargoController.readyToCargoDetail;
  }

  async onEditReadyToCargo(id: number, data: ReadyToCargo) {
    let isAuth = false;
    if (!getTokenNGen() && !data.haveConfigCargoFlight && data.cargoType === "plane") {
      isAuth = await NGenController.onLoginNGen();
    } else {
      isAuth = true;
    }
    if (isAuth) {
      if (this.cargoInputForm.id == 0) {
        await ReadyToCargoController.getDetailReadyToCargo(id, "rtc-edit");
      }
      this.formBooking.actualDimension = new DimensionData()
      CargoController.mappingReadyToCargo();
      this.setFlowBooking("rtc-edit");
      ReadyToCargoController.setEventName("edit_cargo_rtc_booking_result");
      if (this.formBooking.cargoTypeStatus === "auto") {
        this.formBooking.airportOrigin.airportCode =
          ReadyToCargoController.readyToCargoDetail.originCode;
        this.formBooking.airportDestination.airportCode =
          ReadyToCargoController.readyToCargoDetail.destinationCode;
      }
      if (data.isMoreThanCutOffTime) {
        await this.initPlaneAuto(data);
        CargoController.setShowSelectAirportToLocalStorage(true);
        CargoController.setAirportsPopupModel(
          JSON.parse(JSON.stringify(CargoController.form.airports))
        );
        this.showSelectAirport = true;
        this.formBooking.cargoType = "plane";
        this.formBooking.cargoTypeStatus = "auto";
        this.formBooking.isRtc = true;
        this.formBooking.actualTotalCargoGrossWeight = data.totalWeight.toString();
      } else {
        CargoController.setCargoOptions({data: []});
        CargoController.setShowCargoOptions(false);
        CargoController.setProgressValue(0);
        CargoController.setIsEditReadyToCargo(true);
      }
    }
  }

  setFlowBooking(value: string) {
    ReadyToCargoController.setFlowBooking(value);
  }

  get activeTabs () {
    return CargoController.activeTabs;
  }
  get tabs() {
    return CargoController.tabsList;
  }

  getTabs() {
    if(this.originCity.code) {
      CargoController.getCargoOptions({
      origin: {
        code: this.originCity.code||"",
        name: this.originCity.name
      },
      destination: {
        code: "",
        name: ""
      },
      isPlaneManual: false
      });
    }

  }
  changeTabs(value: string) {
    CargoController.setActiveTabs(value);
    ClientController.getRTCListClientParent(value);
    CargoController.setDestinationCityRTC("");
    CargoController.setClientValue("");
    this.readyToCargoList.pagination.page = 1;
    this.fetchReadyToCargoList();
    this.onReset();
  }

  isTabActive(name: string) {
    return name === this.activeTabs;
  }

  cargoType(type: string) {
    return cargoTypeData(type);
  }

  get progress(): number {
    return CargoController.progressValue;
  }

  get flagsGamification() {
    return FlagsGamificationRTC.feature_is_gamification.isEnabled();
  }

  get partnerName() {
    return capitalize(this.dataProfile.account_type_detail.company_name);
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get typeUser() {
    return this.dataProfile.normalizedTypeUser;
  }

  get detailDistrict() {
    return LocationController.districtDetail;
  }

  get isPlaneAuto() {
    return (
      this.formBooking.cargoType === "plane" &&
      this.formBooking.cargoTypeStatus === "auto"
    );
  }

  get totalStt() {
    return this.sttResult.length;
  }

  get totalPieces() {
    return CargoController.totalSttPieces;
  }


  onCloseSuccess() {
    this.setOpenSuccess(false);
    this.$emit("leave-page", "/cargo");
  }

  onCloseProcess() {
    this.$emit("leave-page", "/cargo/booking-in-progress");
  }

  convertProduct(name: string) {
    return name.toLowerCase().includes("express") ? "Express" : "REGULAR";
  }

  get bagResult(): { baggingNumber: string; stt: CargoDetailStt[] }[] {
    return CargoController.bagResult;
  }

  get sttResult(): CargoDetailStt[] {
    return CargoController.sttResultFinal;
  }

  get iconTogglePanel() {
    const refs: any = this.$refs;
    return refs?.op?.visible ? "chevron-up-white" : "chevron-down-white";
  }


  withPrint = false;
  withPrintLabel = false;
  countSuccess = 0;
  countFailed = 0;

  get rcId(): number {
    return Number(this.$route.query.rcId);
  }

  get destinationCity(): OptionsClass {
    return CargoController.destinationCity;
  }

  get isRtc() {
    return (
      ReadyToCargoController.flowBooking === "rtc-process" ||
      ReadyToCargoController.flowBooking === "rtc-edit"
    );
  }

 showPopupTimeout(resp: any) {
    if (resp.bookingRequestId) { //success RTC Ngen
      this.showModalMessageBookingOnProcess(
        `Booking Kargo ${resp.bookingRequestId} <br/> Sedang Diproses`
      );
      this.bookingCargoGTM(this.eventName, "success");
    } else { //timeout RTC Ngen
      if (resp.cargoNo) {
        MainAppController.showMessageModal(
          new ModalMessageEntities({
            title: "Booking Kargo Gagal Diproses nGen",
            message:
              "Lihat alasannya di halaman gagal booking. Cek & booking ulang",
            textSuccess: "OK",
            image: "image-modal-failed",
            onSubmit: () => {
              MainAppController.closeMessageModal();
              this.$emit("leave-page", "/cargo/booking-canceled");
            }
          })
        );
      } else {
        if (resp.totalSttFailed && !resp.totalSttSuccess) {
         this.countFailed = resp.totalSttFailed;
        this.setFailed(true);
        CargoController.setSttFailed(resp.listSttFailed);

        // GTM
        this.bookingCargoGTM(this.eventName, "failed");
        const gtmBookingCargoFailed = gtmCargoData({
          type: this.formBooking.cargoType,
          isAuto: this.isPlaneAuto
        }).bookingCargoFailed;

        dataLayer(
          gtmBookingCargoFailed.eventName,
          gtmBookingCargoFailed.property,
          ["timestamp"]
        );
      } else if (resp.totalSttFailed && resp.totalSttSuccess) {
        this.countFailed = resp.totalSttFailed;
        this.countSuccess = resp.totalSttSuccess;
        this.isPartial = true;
        CargoController.setSttFailed(resp.listSttFailed);
        this.onPrint();
        }
      }
    }
    MainAppController.closeLoading();
  }


  async onSave(): Promise<void> {
    const payloadBagNoOnly = JSON.parse(
      localStorage.getItem("cargo-bag") || "[]"
    )
      .map((item: any) =>
        item.isFromRebooking ? null : new AddBagOrStt(item.baggingNumber)
      )
      .filter((items: any) => items != null);

    const payloadSttNoOnly = JSON.parse(localStorage.getItem("cargo") || "[]")
      .filter(
        (item: any) =>
          !JSON.parse(
            localStorage.getItem("cargo-bag") || "[]"
          ).find((bag: any) => JSON.stringify(bag).includes(item.sttNumber)) ||
          JSON.parse(localStorage.getItem("cargo-bag") || "[]").find(
            (itemBag: any) => item.isFromRebooking == itemBag.isFromRebooking // add validation to check on cargo-bag that come from rebooking
          )
      )
      .map((item: any) => {
        if (item.sttDetails) {
          return item.sttDetails.map(
            (item: any) => new AddBagOrStt(undefined, item.sttNumber)
          );
        } else {
          return new AddBagOrStt(undefined, item.sttNumber);
        }
      })
      .flat();
    if (
      this.formBooking.cargoType === "plane" &&
      this.calculateFlightDate.length &&
      this.flowBooking === "rtc-process"
    ) {
      this.formBooking.airportOrigin.value = this.calculateFlightDate[0]?.flight_origin;
      this.formBooking.airportDestination.value = this.calculateFlightDate[
        this.calculateFlightDate.length - 1
      ]?.flight_destination;
    }
    CargoController.setTimestampBooking(new Date().getTime());
    const resp = await CargoController.onCreateCargo({
      originAirportCode:
        this.formBooking.airportOrigin.value ||
        LocationController.districtDetail.cityCode,
      destinationAirportCode:
        this.formBooking.airportDestination.value ||
        this.formBooking.destinationCityCode,
      estTotalCargoGrossweigth: commaDecimalToDecimal(
        this.formBooking.estTotalCargoGrossWeight
      ),
      estTotalCargoVolumeweight: commaDecimalToDecimal(
        this.formBooking.estTotalCargoVolumeWeight
      ),
      partnerName: this.partnerName,
      partnerType: this.typeUser,
      cargoNo: this.cargoPlaneAutoData.cargoNumber,
      cargoVehicleNo: this.cargoPlaneAutoData.vehicleNumber,
      actualTotalCargoGrossweigth: commaDecimalToDecimal(
        this.formBooking.actualTotalCargoGrossWeight
      ),
      actualTotalCargoVolumeweight: this.formBooking.cargoType === "plane" 
        ? this.recalculateVolumeForDirectMessage() 
        : commaDecimalToDecimal(this.formBooking.actualTotalCargoVolumeWeight),
      route: this.cargoPlaneAutoData.route, // from nGen
      totalStt: this.totalStt,
      totalPiece: this.totalPieces,
      cargoCommodity: this.formBooking.cargoCommodityCode,
      productCargo: this.convertProduct(this.formBooking.cargoProduct),
      cargoType: this.formBooking.cargoType,
      originCityCode: this.detailDistrict.cityCode,
      destinationCityCode: this.formBooking.destinationCityCode,
      departureDate: dateTimePayload(this.cargoPlaneAutoData.estDepartureDate),
      arrivalDate: dateTimePayload(this.cargoPlaneAutoData.estArrivalDate),
      bagOrStt: [...payloadSttNoOnly, ...payloadBagNoOnly],
      isCargoPlaneManual:
        this.formBooking.cargoType === "plane" &&
        this.formBooking.cargoTypeStatus === "manual",
      cargoActualPiece: +this.formBooking.actualTotalSTTPieces,
      flightPlanForECargo: this.cargoPlaneAutoData.flightData,
      dimensions: this.mappingDetailPieces,
      estDimension: this.formBooking.estDimension,
      actualDimension: this.formBooking.actualDimension,
      isFromRtc: this.isRtc,
      rtcId: this.isRtc ? ReadyToCargoController.readyToCargoDetail.id : 0,
      nog: this.formBooking.nog,
      rebookingId: this.rcId,
      ngenCreatedAt: dateTimePayload(this.formBooking.ngenCreatedAt),
      shc: this.formBooking.shcCode
    }).finally(() => {
      MainAppController.closeMessageModal();
    });
    this.onCreateResponse(resp);
  }

  recalculateVolumeForDirectMessage() {
    const dimension = (this.formBooking.actualDimension.width * this.formBooking.actualDimension.height * this.formBooking.actualDimension.length)/FlagsGamificationRTC.dimension_divider.getValue()
    const result = dimension * this.totalPieces
    return result < 1 ? 1 : Math.round(result);
  }

  get cargoPlaneAutoData(): CargoPlaneAutoData {
    if (
      this.formBooking.cargoType === "plane" &&
      this.calculateFlightDate.length &&
      this.flowBooking === "rtc-process"
    ) {
      return new CargoPlaneAutoData({
        estDepartureDate: this.calculateFlightDate[0].flight_dep_datetime,
        estArrivalDate: this.calculateFlightDate[
          this.calculateFlightDate.length - 1
        ].flight_arr_datetime,
        flightData: this.calculateFlightDate
      });
    }
    return new CargoPlaneAutoData({
      estDepartureDate: this.formBooking.estDepartureDate,
      estArrivalDate: this.formBooking.estArrivalDate,
      cargoNumber: this.formBooking.cargoNumber,
      vehicleNumber: this.formBooking.vehicleNumber
    });
  }

  // failed for create
  isPartial = false;
  get timestampBooking() {
    return CargoController.timestampBooking;
  }
  get isFailed(): boolean {
    return CargoController.isFailed;
  }

  get calculateFlightDate() {
    return ReadyToCargoController.calculateFlightDate
  }

  setFailed(value: boolean) {
    CargoController.setFailed(value);
  }

  showModalMessageBookingOnProcess(title: string) {
    MainAppController.showMessageModal(
      new ModalMessageEntities({
        title,
        message: "Anda akan mendapat notifikasi jika booking kargo berhasil",
        textSuccess: "OK",
        image: "illustration_waiting_process",
        onSubmit: () => {
          MainAppController.closeMessageModal();
          this.onCloseSuccess();
        },
      })
    );
  }

  get isBookingV4() {
    return flags.feature_is_booking_cargo_v4.isEnabled();
  }

  onCreateResponse(resp: any) {
    if (resp) {
      if (this.formBooking.cargoType === "plane" && this.isRtc && this.isBookingV4) {
        this.showPopupTimeout(resp);
        return;
      } else if (resp.cargoNo.startsWith("FLIGHT") || resp.isCargoUsingAwb) {
        //error Ngen Non RTC
        this.showModalMessageBookingOnProcess("Booking Kargo Sedang Diproses");
        this.bookingCargoGTM(this.eventName, "success");
      } else if (resp.totalSttFailed && !resp.totalSttSuccess) {
         this.countFailed = resp.totalSttFailed;
        this.setFailed(true);
        CargoController.setSttFailed(resp.listSttFailed);

        // GTM
        this.bookingCargoGTM(this.eventName, "failed");
        const gtmBookingCargoFailed = gtmCargoData({
          type: this.formBooking.cargoType,
          isAuto: this.isPlaneAuto
        }).bookingCargoFailed;

        dataLayer(
          gtmBookingCargoFailed.eventName,
          gtmBookingCargoFailed.property,
          ["timestamp"]
        );
      } else if (resp.totalSttFailed && resp.totalSttSuccess) {
        this.countFailed = resp.totalSttFailed;
        this.countSuccess = resp.totalSttSuccess;
        this.isPartial = true;
        CargoController.setSttFailed(resp.listSttFailed);
        this.onPrint();
      } else {
        // GTM
        this.bookingCargoGTM(this.eventName, "success");
        const gtmBookingCargoSuccess = gtmCargoData({
          type: this.formBooking.cargoType,
          isAuto: this.isPlaneAuto,
          gtmData: {
            cargoNumber: CargoController.cargoIdToGeneratePdf,
            flightNumber: this.formBooking.vehicleNumber
          }
        }).bookingCargoSuccess;

        dataLayer(
          gtmBookingCargoSuccess.eventName,
          gtmBookingCargoSuccess.property,
          ["timestamp"]
        );

        CargoController.setOpenSuccess(true);
        this.onPrint();
      }
    }
  }

  bookingCargoGTM(eventName: any, result: string) {
    GTMBookingCargoEvent(eventName, {
      cargo_type: this.formBooking.cargoType,
      "3LC_origin": this.detailDistrict.cityCode,
      "3LC_destination": this.formBooking.destinationCityCode,
      booking_result: result,
      cargo_number: CargoController.cargoIdToGeneratePdf,
      timestamp_booking: this.timestampBooking,
      timestamp_get_result: new Date().getTime()
    });
  }

  get eventName() {
    return ReadyToCargoController.eventName;
  }

  onPrint() {
    const refs: any = this.$refs;
    if (this.withPrint) {
      refs.print.printManifestV2(CargoController.cargoIdToGeneratePdf);

      // GTM
      const gtmBookingCargoSuccessPDF = gtmCargoData({
        type: this.formBooking.cargoType,
        isAuto: this.isPlaneAuto,
        gtmData: {
          cargoNumber: CargoController.cargoIdToGeneratePdf,
          flightNumber: this.formBooking.vehicleNumber
        }
      }).bookingCargoSuccessPDF;

      dataLayer(
        gtmBookingCargoSuccessPDF.eventName,
        gtmBookingCargoSuccessPDF.property,
        ["timestamp"]
      );
    }
    if (this.withPrintLabel) {
      refs.print.printData(CargoController.cargoIdToGeneratePdf, "label");

      // GTM
      const gtmBookingCargoSuccessLabel = gtmCargoData({
        type: this.formBooking.cargoType,
        isAuto: this.isPlaneAuto,
        gtmData: {
          cargoNumber: CargoController.cargoIdToGeneratePdf,
          flightNumber: this.formBooking.vehicleNumber
        }
      }).bookingCargoSuccessLabel;

      dataLayer(
        gtmBookingCargoSuccessLabel.eventName,
        gtmBookingCargoSuccessLabel.property,
        ["timestamp"]
      );
    }
  }

  get openSuccess(): boolean {
    return CargoController.isOpenSuccess;
  }

  setOpenSuccess(value: boolean) {
    CargoController.setOpenSuccess(value);
  }

  get isShowPopup(): boolean {
    return (
      this.openSuccess ||
      this.isPartial ||
      this.isFailed
    );
  }

  get popupModalData() {
    // if success
    if (this.openSuccess) {
      return {
        success: {
          action: this.onCloseSuccess,
          text: "OK"
        },
        title: "Pembuatan Berhasil !",
        message: `Pengiriman kargo dengan nomor<br /><b>“${CargoController.cargoIdToGeneratePdf}”</b> berhasil dibuat.`,
        image: "image-modal-success"
      };
    }

    // if success partially
    if (this.isPartial) {
      return {
        success: {
          action: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
          text: "Download"
        },
        cancel: {
          action: this.onCloseSuccess,
          text: "OK"
        },

        title: "Pembuatan Berhasil !",
        message: `Beberapa No. STT berhasil dibuat jadwal pengiriman kargo<br />dengan nomor <b>“${CargoController.cargoIdToGeneratePdf}”</b>. Namun terdapat kegagalan<br />pada beberapa STT. Silahkan hapus STT bermasalah untuk<br />melanjutkan pengiriman kargo.`,
        image: "image-modal-warning"
      };
    }

    // default is failed
    return {
      success: {
        action: () => this.onDownloadCsvFailedStt(this.sttFailedUpdate),
        text: "Download"
      },
      cancel: {
        action: () => {
          this.setFailed(false)
          this.onReset();
        },
        text: "OK"
      },
      title: "Pembuatan Gagal !",
      message: "Pengiriman kargo gagal dibuat.",
      image: "image-modal-failed"
    };
  }

  get sttFailedUpdate() {
    return CargoController.sttFailed;
  }

  setPartial(value: boolean) {
    this.isPartial = value;
  }

  onDownloadCsvFailedStt(data: any) {
    this.downloadCsvFailedStt({
      fileName: "cargo_stt_failed.csv",
      listStt: data
    });
  }

  get formDetailPieces() {
    return CargoController.formDetailPieces;
  }

  get isHaveBeenSubmitDetailPieces() {
    return CargoController.isHaveBeenSubmitDetailPieces;
  }

  get mappingDetailPieces() {
    return this.formDetailPieces.dimensionPieces.map((item: any) => {
      return {
        pieces: !this.isHaveBeenSubmitDetailPieces
          ? +this.formBooking.actualTotalSTTPieces
          : +item.pieces,
        length: !this.isHaveBeenSubmitDetailPieces
          ? this.formBooking.actualDimension.length
          : item.length,
        width: !this.isHaveBeenSubmitDetailPieces
          ? this.formBooking.actualDimension.width
          : item.width,
        height: !this.isHaveBeenSubmitDetailPieces
          ? this.formBooking.actualDimension.height
          : item.height
      };
    });
  }

  onChangeNog(value: string) {
    CargoController.setOnChangeNog(value);
  }

  onChangeVehicleNumber(value: string) {
    CargoController.setOnChangeVehicleNumber(value);
  }

  onChangeCargoNumber(value: string) {
    CargoController.setUpdateFromBookingCargoNumber(value);
  }

  onChangePagination(newPage: number) {
    this.readyToCargoList.pagination.page = newPage;
    this.fetchReadyToCargoList();
  }

  get isCargoTrain() {
    return this.formBooking.cargoType === "train";
  }

  get isCargoShip() {
    return this.formBooking.cargoType === "ship";
  }

  get isCargoTruck() {
    return this.formBooking.cargoType === "truck";
  }

  get isCargoPlaneManual() {
    return (
      this.formBooking.cargoType === "plane" &&
      this.formBooking.cargoTypeStatus !== "auto"
    );
  }

  get isCargoPlaneAuto() {
    return (
      this.formBooking.cargoType === "plane" &&
      this.formBooking.cargoTypeStatus === "auto"
    );
  }

  get isFormBookingValid() {
    const cargoNumber =
      this.formBooking.cargoTypeStatus !== "auto" && this.formBooking.cargoType !== "truck"
        ? this.formBooking.cargoNumber
        : true;
    const vehicleNumber =
      this.formBooking.cargoTypeStatus !== "auto"
        ? this.formBooking.vehicleNumber
        : true;
    return !cargoNumber || !vehicleNumber;
  }

  get shcValue() {
    return this.formBooking.shcCode && this.formBooking.shcName ? `${this.formBooking.shcCode} - ${this.formBooking.shcName}` : "-";
  }

  get showModalTest() {
    return CargoController.isShowModalGamification;
  }
}
