<template>
  <svg
    width="101"
    height="9"
    viewBox="0 0 101 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100.354 4.85354C100.549 4.65828 100.549 4.3417 100.354 4.14644L97.1716 0.964458C96.9763 0.769195 96.6597 0.769195 96.4645 0.964458C96.2692 1.15972 96.2692 1.4763 96.4645 1.67156L99.2929 4.49999L96.4645 7.32842C96.2692 7.52368 96.2692 7.84026 96.4645 8.03553C96.6597 8.23079 96.9763 8.23079 97.1716 8.03553L100.354 4.85354ZM4.37114e-08 5L100 4.99999L100 3.99999L-4.37114e-08 4L4.37114e-08 5Z"
      fill="#CCCCCC"
    />
  </svg>
</template>

<script>
import { Vue } from "vue-class-component";

export default class LongArrow extends Vue {}
</script>
