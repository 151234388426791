
import { FlightDetail } from "@/domain/entities/NGen";
import { Options, prop, Vue } from "vue-class-component";
import {
  dateDiffHoursMinute,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import LongArrow from "@/app/ui/components/icons/modules/long-arrow.vue";
class Props {
  flightDetail = prop<FlightDetail>({
    default: new FlightDetail(),
    type: FlightDetail
  });
  flightIndex = prop<number>({
    default: 0,
    type: Number
  });
}
@Options({
  components: { LongArrow },
})
export default class CardDetailFlight extends Vue.with(Props) {
  dateDiffHoursMinute(startDate: any, endDate: any) {
    return dateDiffHoursMinute(startDate, endDate);
  }
  formatDateWithoutTime(value: string): string {
    return formatDateWithoutTime(value);
  }
  formatTimeNumber(time: string) {
    return time?.slice(0, time.length - 3)
  }
}
