import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  class: "rounded overflow-hidden flex flex-col",
  style: {"flex-shrink":"0"}
}
const _hoisted_2 = { class: "px-4 py-2.5 text-14px font-semibold bg-gray-lp-1600 text-gray-lp-300" }
const _hoisted_3 = {
  key: 0,
  class: "font-normal ml-6"
}
const _hoisted_4 = { class: "flex items-center gap-8 px-8 py-6 text-black-lp-300 rounded-b-md border-l border-r border-b border-gray-lp-1600" }
const _hoisted_5 = { style: {"max-width":"132px"} }
const _hoisted_6 = {
  key: 0,
  class: "text-xl font-semibold"
}
const _hoisted_7 = {
  key: 1,
  class: "text-sm text-gray-lp-800 h-4 xl:h-5 truncate"
}
const _hoisted_8 = {
  key: 0,
  class: "flex gap-2.5 flex-col justify-center items-center"
}
const _hoisted_9 = { class: "text-xs text-gray-lp-600" }
const _hoisted_10 = { class: "py-2 px-6 rounded bg-gray-lp-1500" }
const _hoisted_11 = { class: "text-14px font-medium text-black-lp-300" }
const _hoisted_12 = {
  class: "col-span-2",
  style: {"max-width":"132px"}
}
const _hoisted_13 = {
  key: 0,
  class: "text-xl font-semibold"
}
const _hoisted_14 = {
  key: 1,
  class: "text-gray-lp-800 text-sm h-4 xl:h-5 truncate"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LongArrow = _resolveComponent("LongArrow")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createTextVNode(" Jadwal Pesawat " + _toDisplayString(_ctx.flightIndex + 1) + " ", 1),
      (_ctx.flightDetail.flightDate || _ctx.flightDetail.DepartureDateTime)
        ? (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.formatDateWithoutTime(
          new Date(_ctx.flightDetail.flightDate || _ctx.flightDetail.DepartureDateTime).toISOString()
        )), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_4, [
      _createVNode("div", _hoisted_5, [
        (_ctx.flightDetail.departureHour || _ctx.flightDetail.DepartureDateTime)
          ? (_openBlock(), _createBlock("p", _hoisted_6, _toDisplayString(_ctx.formatTimeNumber(_ctx.flightDetail.departureHour || _ctx.flightDetail.DepartureDateTime?.split(" ")[1])), 1))
          : _createCommentVNode("", true),
        (_ctx.flightDetail.airportCodeOrigin || _ctx.flightDetail.Route.length)
          ? (_openBlock(), _createBlock("p", _hoisted_7, _toDisplayString(_ctx.flightDetail.airportCodeOrigin || _ctx.flightDetail.Route?.split("-")[0] || ""), 1))
          : _createCommentVNode("", true)
      ]),
      (
          (_ctx.flightDetail.DepartureDateTime || _ctx.flightDetail.origin) &&
            (_ctx.flightDetail.ArrivalDateTime || _ctx.flightDetail.destination)
        )
        ? (_openBlock(), _createBlock("div", _hoisted_8, [
            _createVNode("p", _hoisted_9, _toDisplayString(_ctx.dateDiffHoursMinute(
              _ctx.flightDetail.DepartureDateTime,
              _ctx.flightDetail.ArrivalDateTime
            )), 1),
            _createVNode(_component_LongArrow),
            _createVNode("div", _hoisted_10, [
              _createVNode("p", _hoisted_11, _toDisplayString(_ctx.flightDetail.FlightNo), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode("div", _hoisted_12, [
        (_ctx.flightDetail.arrivalHour || _ctx.flightDetail.ArrivalDateTime)
          ? (_openBlock(), _createBlock("p", _hoisted_13, _toDisplayString(_ctx.formatTimeNumber(_ctx.flightDetail.arrivalHour || _ctx.flightDetail.ArrivalDateTime?.split(" ")[1])), 1))
          : _createCommentVNode("", true),
        (_ctx.flightDetail.airportCodeDestination || _ctx.flightDetail.Route.length)
          ? (_openBlock(), _createBlock("p", _hoisted_14, _toDisplayString(_ctx.flightDetail.airportCodeDestination ||  _ctx.flightDetail.Route?.split("-")[1] || ""), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}